.headerDiv{
    display:flex;
    align-items:baseline;
    height: 100px;
    width: 100%;
    padding: 45px;
    margin: 0px;
    position: absolute;
    z-index: 2;
}

.headerBackground{
    height: 100px;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 12;
    position: absolute;
    border: 90px solid;
    border-image: url(/src/Window3.png) 210;
    box-sizing:border-box;
    z-index: 8;
}

#proftxt
{
    color: var(--light);
    text-shadow: 
            -1px 1px 0 var(--dark),
            1px 1px 0 var(--dark),
            1px -1px 0 var(--dark),
            -1px -1px 0 var(--dark);
    z-index: 2;
}

.animatedBackground
{
    overflow: hidden;
    height: 155px;
    width: 100%;
    position: relative;
    padding: 10px;
    margin: 0px;
    border: 0px;
    z-index: 1;
}

@keyframes diagonal {
    from {transform: translateY(-100px) translateX(-100px)}
    to {transform: translateY(-50px) translateX(-50px)}
}

:root
{
    --accent-light:#ff847c;
    --accent:#e84a5f;
    --light:#fecea8;
    --dark:#45171d;
}

.checkerboard
{
    background-color: var(--accent-light);
    background-size: 50px 50px;
    background-position: 25px 25px, 25px 0, 0 0, 0 25px;
    background-image:
      linear-gradient(45deg, var(--accent) 26%, transparent 25%),
      linear-gradient(-45deg, var(--accent) 26%, transparent 25%),
      linear-gradient(45deg, transparent 75%, var(--accent) 75%),
      linear-gradient(-45deg, transparent 75%, var(--accent) 75%);
    width: calc(120vw + 400px);
    height: 300px;
    margin: 0px;
    padding: 0px;
    content: 100%;
    z-index: -2;
    animation-name: diagonal;
    animation-timing-function: linear;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

.aboutDiv
{
    display: flex;
    position: absolute;
    padding: 10px;
    width: 100%;
    z-index: 6;
    background-color: transparent;
}

.aboutBackground
{
    display: block;
    transform:translateY(-300px);
    position: absolute;
    height: 200vh;
    width: 100vw;
    z-index: -11;
    background-image: linear-gradient(to bottom, #7851a9, #b14b9f, #dc4988, #f6576a, #ff7449, #ff8939, #ff9f26, #ffb60b, #ffc116, #ffcc20, #ffd62a, #ffe135);
}
.gridTexture
{
    display: block;
    position: absolute;
    background-color: transparent;
    transform: translateX(-10px) translateY(-300px);
    z-index: -10;
    height: 200vh;
    width: 110vw;
    background-size: 40px 40px;
    background-image: linear-gradient(to right, rgba(211, 211, 211, 0.918) 2px, transparent 1px),
                      linear-gradient(to bottom, rgba(211, 211, 211, 0.918) 2px, transparent 1px);
}

.container
{
    position: relative;
}

.navbarContainer
{
    display: flex;
    height: 50px;
    width: 100%;
    z-index: 7;
    background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
    position: relative;
}

.navbarDiv
{
    position: absolute;
    overflow: hidden;
    display: flex;
    height: 50px;
    z-index: 9;
    justify-content: left;
}

.navbarBackground
{
    position: absolute;
    display: block;
    z-index: 7;
    height: 30px;
    width: 100%;
    border: rgb(143, 112, 216) solid 7px;
    background-color: rgb(175, 155, 222);
    align-self: end;
    box-sizing:border-box
}

.navButton
{
    display: flex;
    height: 30px;
    width: 150px;
    background-image: url(/src/tab2.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    align-self: center;
}
.pictureFrame
{
    position: absolute;
    overflow: hidden;
    box-shadow: 10px 10px 5px rgb(0 0 0 / 30%);
    width: 400px;
    height: 400px;
}
.frame
{
    position: absolute;
    width: 400px;
    display: block;
    height: 400px;
    object-fit: cover;
    z-index: 2;
}
.picture
{
    position: absolute;
    width: 400px;
    height: auto;
    display: block;
    padding: 0px;
    margin: 0px;
    z-index: 1;
}

.pin
{
    position: absolute;
    height: auto;
    width: auto;
    z-index: 20;
}
.sparkles
{
    position: absolute;
    height: auto;
    width: auto;
    z-index: 20;
    animation-name: bob;
    animation-timing-function: linear;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes bob {
    0% {transform: translateY(0px)}
    50% {transform: translateY(30px)}
    100% {transform: translateY(0px)}
}

.pictureFrameB
{
    position: absolute;
    overflow: hidden;
    height: 400px;
    box-shadow: 10px 10px 5px rgb(0 0 0 / 30%);
    width: 400px;
    display: block;
}
.frameB
{
    position: absolute;
    width: 400px;
    height: 400px;
    display: block;
    object-fit: cover;
    z-index: 2;
}
.pictureB
{
    position: absolute;
    width: 400px;
    height: 300px;
    display: block;
    object-fit: cover;
}
.sticky
{
    position: fixed;
}

.textblock
{
    display : block;
    position:absolute;
    justify-content: center;
    align-items: start;
    width: 340px;
    height: 309px;
    padding: 5px;
    transform: translateX(31px) translateY(60px);
    background-color: var(--light);
}

.navText
{
    align-self: flex-end;
    margin: 0px 0px 0px 15px;
    font: 20 20px Arial;
    -webkit-text-fill-color: white;
    -webkit-text-stroke: 1px;
    -webkit-text-stroke-color: white;
}

.noselect 
{
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

html
{
    overflow-x: hidden;
}

body 
{
    overflow-x: hidden;
    position: relative;
    background-size: 40px 40px, 40px 40px, cover;
    background-image: linear-gradient(to right, rgba(211, 211, 211, 0.918) 2px, transparent 1px),
                      linear-gradient(to bottom, rgba(211, 211, 211, 0.918) 2px, transparent 1px),
                      linear-gradient(to bottom, #7851a9, #b14b9f, #dc4988, #f6576a, #ff7449, #ff8939, #ff9f26, #ffb60b, #ffc116, #ffcc20, #ffd62a, #ffe135);
    background-repeat: repeat, repeat, no-repeat;
    background-attachment: fixed;
}

.pictureBoard
{
    position: absolute;
    left: 1300px;
}

@media (max-width: 479px) {
    .textBoxes{
        scale: .4;
    }
    .aboutDiv
    {
        align-items: flex-start;
    }
    .pictureBoard
    {
        scale: .35;
        left: 200px;
        top: 300px;
    }
}


#root
{
    height: 100vh;
}